:root
{
    --text: #f0ebf6;
    --background: #0f0816;
    --primary: #be9ddf;
    --secondary: #4e227a;
    --accent: #8d44d5;
    --glass: rgba(78, 34, 122, .4);
    --bg-svg: 0%;
    --icons: 100%;
}

[data-theme="light"] {
    --text: #090602;
    --background: #ebe0ca;
    --primary: #c98106;
    --secondary: #abbce0;
    --accent: #c98106;
    --glass: #abbce0;
    --bg-svg: 100%;
    --icons: 0%;
}