.tech-card
{
    height: 60px;
    background-color: var(--glass);
    backdrop-filter: blur(2px);

    display: flex;
    align-items: center;

    border-radius: .375rem;

    div#icon
    {
        margin-left: 10px;

        height: 36px;
        width: 36px;
        background-color: rgba(0,0,0,.5);
        border-radius: .5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        img
        {
            height: 20px;
            width: 20px;
        }
    }

    p
    {
        margin-left: 10px;
    }
}