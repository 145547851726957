
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

    h3
    {
      color: var(--text);
      text-decoration: underline;
    }
    
    p
    {
      color: var(--text);
    }

    /* Container around content */
    .container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 170px;
    }

    /* The circles on the timeline */
    .container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: var(--secondary);
    border: 4px solid var(--primary);
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    }



    /* Add arrows to the left container (pointing right) */
    .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid var(--secondary);
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent var(--secondary);
    }

    /* Add arrows to the right container (pointing left) */
    .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid var(--secondary);
    border-width: 10px 10px 10px 0;
    border-color: transparent var(--secondary) transparent var(--secondary);
    }

    /* Fix the circle for containers on the right side */
    .right::after {
    left: -16px;
    }

    /* The actual content */
    .content {
    padding: 20px 30px;
    background-color: var(--secondary);
    position: relative;
    border-radius: 6px;
    }


}

.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: var(--secondary);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

@media screen and (min-width: 601px) {
  /* Place the container to the left */
  .left {
      left: -125px;
  }

  /* Place the container to the right */
  .right {
  left: 125px;
  }
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
/* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

/* Full-width containers */
  .timeline .container {
    width: calc(100dvw - 120px);
    margin: 0;
  }

/* Make sure that all arrows are pointing leftwards */
  .timeline .container::before {
    left: 30px;
    border: medium solid var(--secondary);
    border-width: 10px 10px 10px 0;
    border-color: transparent var(--secondary) transparent transparent;
  }

  .timeline .container.left
  {
    left: 15px;
    margin-left: 15px;
  }
/* Make sure all circles are at the same spot */
  .left::after
  {
    left: -15px;
  }
  
  .right::after {
    left: 0;
  }

/* Make all right containers behave like the left ones */
  .right {
    left: 30px;
  }
}