main#dashboard
{

    .tabs
    {

        ul
        {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            gap: 25px;

            li
            {
                color: var(--primary);
                cursor: pointer;

                &:hover
                {
                    color: var(--accent);
                }

                &.active
                {
                    text-decoration: underline;
                }
            }
        }
    
    }

    

}