.work-card
{
    min-height: 250px;
    background-color: var(--glass);
    backdrop-filter: blur(2px);

    border-radius: .375rem;

    .head
    {
        display: flex;
        align-items: center;    
        
        margin-top: 10px;

        div#icon
        {
            margin-left: 10px;

            height: 36px;
            width: 36px;
            background-color: rgba(0,0,0,.5);
            border-radius: .5rem;

            display: flex;
            justify-content: center;
            align-items: center;

            img
            {
                height: 20px;
                width: 20px;
            }
        }

        p, a
        {
            margin-left: 10px;
        }
        
    }

    .body p
    {
        margin: 10px;
    }


    .footer
    {

        margin: 10px;

        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        max-height: 150px;

        overflow: hidden;

        .tag
        {
            padding: 5px 10px;
            border-radius: 10px;
            font-size: 14px;
            height: fit-content;
            color: black;
        }

        .tag.red {background-color: pink;}
        .tag.yellow {background-color: lightblue;}
        .tag.green {background-color: rgb(68, 136, 62);}

    }
}