*
{
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: var(--text);
}

.App
{
    // background: linear-gradient(38deg, rgba(63,46,173,1) 15%, rgba(125,115,193,1) 100%);;
    // background: linear-gradient(45deg ,var(--grad-btm), var(--grad-top));
    background-color: var(--background);
    min-height: 100vh;

    position: relative;

}

.App::before
{
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    inset: 0;
    position: absolute;
    background-image: url("../../../public/grid.svg");
    background-repeat: repeat;
    opacity: .5;
    pointer-events: none;
    
    filter: invert(var(--bg-svg)) 
}

.container
{
    margin: auto;
}

.highlight
{
    color: var(--accent);
}

.del-btn
{
    text-decoration: underline;
    color: rgb(255, 85, 85);
    cursor: pointer;

    &:hover
    {
        color: red;
    }
}

@media screen and (min-width: 1150px) {
    .container
    {
        width: 1150px;
    }
}

@media screen and (max-width: 1149px) {
    .container 
    {
        width: 90dvw;
    }
}

@media screen and (max-width: 10px) {
    .container 
    {
        width: 90dvw;
    }
}
