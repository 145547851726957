.main-nav
{
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 110px;

    .main-logo
    {
        img {
            height: 60px;
            width: 60px;
        }

        &:hover{
            rotate: -45deg;
            transition-duration: 0.2s;
        }
        transition-duration: 0.2s;
    }

    .controls
    {

        display: flex;

        gap: 10px;

        .nav-btn
        {
            border-radius: 100px;
            border: none;
            background-color: rgba($color: #000000, $alpha: .4);

            color: white;
            text-decoration: none;


            height: 45px;
            width: 45px;

            display: flex;
            justify-content: center;
            align-items: center;
            
            font-size: 20px;
            

            cursor: pointer;

            svg {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(166deg) brightness(100%) contrast(104%);
                height: 20px;
                width: 20px;
            }


            &:hover
            {
                background-color: var(--glass);
            }
        }

        .lang-btns
        {
            display: flex;
            flex-direction: row-reverse;
            justify-content: baseline;

            gap: 10px;
            
            font-size: 20px;

        }
    }

    

}