main
{
    hr 
    { 
        margin-bottom: 15px; 
        color: var(--primary);
    }
    h2 
    { 
        margin-bottom: 12px ;
        color: var(--primary);
    }

    #introduction
    {
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .texts
        {
           width: 60%;
        }

        .protrait
        {
            height: 150px;
        }

    }

    #works .content
    {
        margin: 25px 0 50px 0;
    }

    #technologies
    {

        .category
        {

            margin: 25px 0;

            h3
            {
                font-size: 16px;
                margin: 15px;  
            }
        }
    }


    .grid
    {
        padding: 0;
        margin: 0;
        display: grid;
        row-gap: .5rem;
        column-gap: .5rem;

        list-style: none;
    }

        
    @media screen and (min-width: 1150px) {
        .grid
        {
            grid-template-columns: repeat(4, minmax(0,1fr));
        }
        
    }

    @media screen and (max-width: 1149px) {
        #introduction
        {
            height: fit-content;
            padding: 10px 0;
        }
        .grid
        {
            grid-template-columns: repeat(2, minmax(0,1fr));
        }
    }

    @media screen and (max-width: 760px) {
        #introduction
        {
            font-size: 14px;

            .texts 
            {
                width: 100%;
            }

            img
            {
                display: none;
            }
        }
        .grid
        {
            grid-template-columns: repeat(1, minmax(0,1fr));
        }
    }


}